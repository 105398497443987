@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700|Roboto:400,300,700,500,100);
@import url(https://fonts.googleapis.com/css?family=Philosopher&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
* {
  margin: 0;
  padding: 0;
}
body {

  font-family: 'Roboto', 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right:0px !important;
  overflow-y: overlay;

}

.Circle_loader__2hZ7t,
.Circle_loader__2hZ7t:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.Circle_loader__2hZ7t {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid #3d2a3b;
  border-right: 1.1em solid #3d2a3b;
  border-bottom: 1.1em solid #3d2a3b;
  border-left: 1.1em solid #80627d;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: Circle_load8__2CAce 1.1s infinite linear;
  animation: Circle_load8__2CAce 1.1s infinite linear;
  overflow: hidden;
}
@-webkit-keyframes Circle_load8__2CAce {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes Circle_load8__2CAce {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

